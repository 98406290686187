<template>
  <div class="recommand-detail">
    <div class="recomand-top-box">
      <div class="image-box">
        <van-image
          width="40px"
          height="40px"
          fit="fill"
          radius="4px"
          :src="talkItem.avatar"
        />
      </div>
      <div class="recommand-item-content">
        <div class="user-name">
          <span v-if="talkItem.remark">
            {{ talkItem.remark }}({{ talkItem.name }})
          </span>
          <span v-else>{{ talkItem.name }}</span>
          <div class="conversation-time">
            {{ timeformat(talkItem.msg_time) }}
          </div>
        </div>
        <div class="recommand-content-box">
          <div class="recommand-item-text-box">
            <div class="recommand-item-text" v-html="contentHtml"></div>
          </div>
          <!-- <div
            @click="openCommand(talkItem.work_message_id)"
            :class="['open-btn', isOpen ? 'open-btn-active' : '']"
          >
            <van-icon name="play" size="16px" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="detail-content">
      <TextContent
        v-for="textItem in textList"
        :content="textItem.content"
      ></TextContent>
      <div class="content-media">
        <MediaGride
          v-if="mediaList.length"
          :detail="detail"
          :mediaList="mediaList"
          pageType="knowledge"
        ></MediaGride>
      </div>
    </div>
    <div class="bottom-btn-box">
      <div class="send-btn-part" @click="onSend(true)">
        <img
          class="send-icon"
          src="@/assets/img/quickSend/review.svg"
          alt="分享"
        />
        <div class="send-text">引用消息回复</div>
      </div>
      <div class="send-btn-part" @click="onSend(false)">
        <img
          class="send-icon"
          src="@/assets/img/suggestion/share.svg"
          alt="分享"
        />
        <div class="send-text">直接发送</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import moment from 'moment'
import TextContent from '@/components/detail/TextContent'
import {
  getKnowledgeDetail,
  setBackSend,
  sendMonitor
} from '@/service/quickSend.service.js'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import MediaGride from '@/components/detail/mediaGride.vue'
import { heightLightArr } from '../../../../utils/index'
import { detail } from '../tabPage/test'
import { formatParamsDistribution } from '@/utils/index.js'

export default {
  components: {
    [VanImage.name]: VanImage,
    TextContent: TextContent,
    MediaGride: MediaGride
  },
  data() {
    return {
      talkItem: {},
      params: {
        sale_script_id: ''
      },
      detail: {},
      textList: [],
      mediaList: [],
      keyArr: [],
      customer_userid: '',
      chat_id: '',
      share_data_id: ''
    }
  },
  computed: {
    // 内容中关键词飘红
    contentHtml() {
      if (!this.talkItem) return ''
      // console.log('关键词：', this.keyArr)
      let strHtml = heightLightArr(this.keyArr, this.talkItem.content)
      return strHtml
    }
  },
  created() {
    // 获取对话信息
    let str = sessionStorage.getItem('recomandDetail')
    this.talkItem = JSON.parse(str)

    // 关键词数组
    let keyArrSession = sessionStorage.getItem('recomandDetailKey')
    let keyArrParams = JSON.parse(keyArrSession)
    keyArrParams.forEach((item) => {
      this.keyArr.push(item.keyword)
    })
  },
  mounted() {
    this.params.sale_script_id = this.$route.query.sale_script_id || ''
    this.customer_userid = this.$route.query.customer_userid || ''
    this.chat_id = this.$route.query.chat_id || ''
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail() {
      // this.detail = detail.data || {}
      // console.log('测试数据', this.detail.content_arr)
      // this.textList = this.detail.content_arr.filter((item) => {
      //   return item.type == 'TEXT'
      // })
      // this.mediaList = this.detail.content_arr.filter((item) => {
      //   return item.type != 'TEXT'
      // })

      // 接口请求
      getKnowledgeDetail(this.params)
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data || {}
            // 过滤文字内容和媒体内容
            this.textList = this.detail.content_arr.filter((item) => {
              return item.type == 'TEXT'
            })
            this.mediaList = this.detail.content_arr.filter((item) => {
              return item.type != 'TEXT'
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },

    // 日期展示规则
    timeformat(date) {
      // 今天最后的时间
      let todayEnd = moment(new Date()).endOf('day')
      let dayLong = 24 * 60 * 60 * 1000
      let dateInt = moment(date).unix() * 1000
      // console.log('-----------------', dateInt)
      let timeDate = ''
      if (todayEnd - dateInt < dayLong) {
        // 今日内
        timeDate = moment(dateInt).format('HH:mm')
      } else if (
        todayEnd - dateInt > dayLong &&
        todayEnd - dateInt < dayLong * 2
      ) {
        //昨天
        timeDate = '昨天' + moment(dateInt).format('HH:mm')
      } else if (
        todayEnd - dateInt > dayLong * 2 &&
        todayEnd - dateInt < dayLong * 7
      ) {
        let arr = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六'
        ]
        let index = moment(dateInt).day()
        timeDate = arr[index] + ' ' + moment(dateInt).format('HH:mm')
      } else {
        timeDate = moment(dateInt).format('MM-DD')
      }
      // console.log('转化后', timeDate)
      return timeDate
    },

    // 发送前的埋点回调
    async beforeSend() {
      let { data, code } = await sendMonitor({
        payable_id: this.params.sale_script_id,
        type: 'reply',
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    // 点击发送
    async onSend(isReview) {
      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        let mediaList = JSON.parse(JSON.stringify(this.detail.content_arr))
        // 回复点击判断第一条是否文字内容
        if (isReview) {
          if (mediaList[0].type == 'TEXT') {
            let name = this.talkItem.name
            let reviewContent = `|@${name}: \n|${this.talkItem.content}\n ---------- \n${mediaList[0].content}`
            mediaList[0].content = reviewContent
          } else {
            // 第一条不是文字的话，添加一个回复头
            let obj = {
              type: 'TEXT',
              content: `|@${this.talkItem.name}: \n|${this.talkItem.content}\n----------`
            }
            mediaList.unshift(obj)
          }
        }

        for (let i = 0; i < mediaList.length; i++) {
          let type = await this.formatType(mediaList[i].type)
          let params = await this.formatSendParams(
            type,
            mediaList[i],
            this.share_data_id
          )
          // console.log(3)
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          // console.log('发送状态：-----------：', sendStatus)
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }

        if (this.taskStatus) {
          this.onSended()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 发送之后进行回调
    async onSended() {
      let type = 'customer_receive_member_reply'
      let field = 'external_contact'
      if (this.chat_id) {
        type = 'customer_touch_member_reply_group'
        field = 'chat_id'
      }

      await setBackSend({
        sale_script_id: this.params.sale_script_id,
        event_key: type,
        field: field,
        field_value: this.customer_userid || this.chat_id
      })

      // 发送完成后关闭当前窗口
      // window.location.href = 'about:blank'
      // window.close()
      wx.closeWindow()
    },

    // 判断wechat文件类型
    formatType(medisType) {
      let type = ''
      switch (medisType) {
        case 'TEXT':
          type = 'text'
          break
        case 'IMAGE':
          type = 'image'
          break
        case 'LINK':
          type = 'news'
          break
        case 'VIDEO':
          type = 'video'
          break
        case 'MINIPROGRAM':
          type = 'miniprogram'
          break
        case 'FILE':
          type = 'news'
          break
        default:
          type = 'text'
          break
      }
      // console.log(1, type)
      return Promise.resolve(type)
    },

    async formatSendParams(type, item, share_data_id) {
      // console.log('参数', type, item)
      // 缺少http头部，补充
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl = item.file_url
            ? item.file_url
            : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.qw_link_url, //H5消息页面url 必填
            title: item.qw_link_title, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = await formatParamsDistribution(
          item.qw_miniprogram_page,
          this.customer_userid,
          this.chat_id,
          'recommand'
        )
        // 如果存在埋点id加入埋点id
        if (share_data_id) path = `${path}&share_data_id=${share_data_id}`
        params[type] = {
          appid: item.qw_miniprogram_appid, //小程序的appid
          title: item.qw_miniprogram_title, //小程序消息的title
          imgUrl: item.file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      // console.log(2, params)
      return Promise.resolve(params)
    },

    // 处理小程序路径加上分配所需参数
    // formatParamsDistribution(path) {
    //   let obj = {
    //     member_id: localStorage.getItem('member_id') || '',
    //     customer_id: this.customer_userid || '',
    //     chart_id: this.chat_id || '',
    //     source: 'label'
    //   }
    //   let tempPath = path
    //   for (let key in obj) {
    //     if (obj[key]) {
    //       tempPath = `${tempPath}&${key}=` + obj[key]
    //     }
    //   }
    //   return Promise.resolve(tempPath)
    // },

    // 发送时获取图片封面
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/style/bottom_btn.less');
.recomand-top-box {
  background-color: #fff;
  display: flex;
  padding: 15px;
  .image-box {
    margin-top: 3px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    flex-grow: 0;
  }
}
.recommand-item-content {
  box-sizing: border-box;
  flex-grow: 1;
  // max-width: calc(100vw - 110px);
  padding-left: 15px;
  .user-name {
    line-height: 17px;
    font-size: 12px;
    color: #5f5f5f;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;

    .conversation-time {
      font-size: 10px;
      line-height: 14px;
      color: #aaaaaa;
      // margin-bottom: 16px;
      white-space: nowrap;
    }
  }
  .recommand-content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .recommand-item-text-box {
      padding: 6px 8px;
      background-color: #f5f5f5;
      border-radius: 5px;
      position: relative;

      &:before {
        width: 0;
        height: 6px;
        content: '';
        border: 6px solid #f5f5f5;
        position: absolute;
        border-color: transparent #f5f5f5 transparent transparent;
        top: 10px;
        left: -12px;
      }
    }
    .recommand-item-text {
      line-height: 20px;
      font-size: 14px;
      color: #171717;
      word-break: break-all;
    }
  }
}
.detail-content {
  margin-top: 8px;
  padding: 15px;
  padding-bottom: 65px;
  background-color: #fff;
}
</style>
